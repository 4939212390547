@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --secondary: rgba(239, 214, 77, 0.523);
  --darkgrey: #22262a;
  --lightgrey: #d3d3d3;
  --grey: #808080;
  --white: #f8f8f8;
}

a {
  text-decoration: none;
}

body {
  color:black;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}


.crsl {
  width:  400px;
  margin: auto;
}

@media screen and (max-width: 480px) {
  .crsl {
    width:  200px;
    margin: auto;
  }
}