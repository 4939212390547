.whatweCont {
  background: rgba(248, 248, 248, 0.431) center no-repeat;
  background-size: 50%;
  background-position-x: 150%;
  height: 100%;
  width: 100%;
  padding: 12% 3%;
  display: flex;
  gap: 7em;
  justify-content: space-between;
  margin: 15% 0 3% 0;
}

.whatwe {
  max-width: 50%;
  min-height: 90%;
  padding: 5%;
  background: rgba(250, 235, 99, 0.139);
  border-radius: 3px;
}


.whatwe span {
  transform: scale(1.2);
}

.whatwe .smallerp {
  font-size: 15px;
  margin-top: 10px;
  width: 90%;
  position: relative;
}

.whatwe .biggerp {
  font-size: 20px;
  font-weight: bold;
  width: 90%;
  line-height: 1.5em;
  position: relative;
  text-transform: uppercase;
}
.medias{
  max-width: 75%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3em;
  /* border: 2px solid red; */
  
}

.medias .img1 {
  position: relative ;
  top: 5%;
  left: 19%;
  max-width: 75%;
  
}

.medias .speech{
  position: absolute;
  width: 35%;
  
}

.blink {
  color: rgb(201, 8, 8);
  animation: blinks 1s linear infinite;
}

@keyframes blinks {
  0% {
    color: red;
    opacity: 1;
  }

  40% {
    color: rgba(255, 0, 0, 0.587);
    opacity: 0;
  }

  60% {
    color: rgba(239, 207, 3, 0.955);
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    color: var(--darkgrey);
    opacity: 1;
  }
}


@media (max-width: 900px) {

  .whatweCont{
    flex-direction: column;
    gap: 3rem;
    padding: 0 2%;
    background: none;
    margin-top: -15%;
  }

  .whatwe{
    min-width: 90%;
    max-height: 80%;
  }

  .whatwe h2{
    font-size: 20px;
  }

  .whatwe .biggerp{
    width: 100%;
  }

  .whatwe .smallerp{
    width: 100%;
  }


  .medias{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
    
  }

  .medias .img1 {
    position: static;
    max-width: 75%;
    
  }

  .medias .speech{
    width: 100%;
    position: static;
    
  }
  
}

@media (max-width: 1000px){
  
  .whatweCont{
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   ;
  }
  
  .whatwe{
    max-width: 80%;
    min-height: 70%;
    margin: 7% 0 3% 0
  }

  .medias{
    width: 100%;
  }
  
  .medias .img1{
top: 5%;
max-width: 70%;
left: 15%;
  }

  .medias .speech{
top: 5%;
max-width: 60%;
left: 8%;

  }

}

@media (max-width: 1200px){
  .whatweCont{
    margin: 25% 0 3% 0;
    padding: 5% 5% 2%;;
}

.medias{
  width: 90%;
}

.medias .img1{
  top: 5%;
  max-width: 70%;
  left: 30%;
    }

}