.pointers{
    display: flex;
    justify-content: center;
    gap: 2rem;
    text-align: center;
    padding: 3% 3% 2%;
    font-size: 14px;
    align-items: center;
}



.featurescont{
    display: block;
    position: absolute;
    top: 90%;
    max-width: 75%;
    background: whitesmoke no-repeat center;
    background-size: cover;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.245);

}

.featicon3{
    color: rgba(203, 7, 7, 0.876);
}
.featicon2{
    color: rgba(239, 207, 3, 0.955);
}
.featicon1{
    color: --darkgrey;
}

@media (max-width: 900px) {
    .pointers{
        flex-direction: column;
        gap: 3em;
        padding: 2em 3em;
        object-fit: cover;
        
    } 

    .featurescont{
    display: none;
    z-index: 0;
    position: relative;
    top: 90%;
    width: 100%;
    background: whitesmoke no-repeat center;
    background-size: cover;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: none;
    }
    .pointers .divs ~ p{
        font-size: 12;
        }
}