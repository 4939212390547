.featurescont1{
    display: none;
}

@media (max-width: 900px) {
    .pointers1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        text-align: center;
        padding: 6% 4% 20%;
        font-size: 14px;
    }
    
    .featurescont1{
        display: block;
        width: 100%;
        background: whitesmoke no-repeat center;
        background-size: cover;
        margin: 0 auto;
        border-radius: none;
        box-shadow: none;
    
    }
    
    .featicon6{
        color: rgba(203, 7, 7, 0.876);
    }
    .featicon5{
        color: rgba(239, 207, 3, 0.955);
    }
    .featicon4{
        color: --darkgrey;
    }
     
}